
@import "colors";

footer{
    margin-top: auto;
    width: 100vw;
    height: fit-content;
    padding: 1rem 2rem;
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    // background-color: rgb(174, 64, 100);
    background-color: #333333;
    box-shadow: 0px -5px 12px -6px rgba(0,0,0,0.73);

    span{
        color: $core-light;
        font-size: 0.7rem;
    }

    a{
        margin-right: 0.7rem;
        text-decoration: none;
        font-size: 0.6rem;
        color: $core-light;

        &:hover{
            text-decoration: underline;
        }
    }
}