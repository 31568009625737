
.year-label{
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    border-bottom: solid 0.2rem;
    text-align: right;
    color: #da5540 !important;
}

.bibliography>ol>li{
    list-style-type: none;
}

.bib-entry{
    
    display: none;
    flex-direction: column;
    padding: 0.25rem;
    border-radius: 0.5rem 0  0.5rem 0;
    border-color: #ff4e3c;
    border-width: 0.05rem;
    border-style: solid;
    background-color: #333333;
    margin-bottom:1em;
    transition: all 125ms ease-in-out;

    a:hover{
        text-decoration:none !important;
    }

    span{
        // margin-bottom: 0.2rem;
        line-height: 1rem;
        font-weight: lighter;
    }

    .title{
        font-weight: 700;
    }

    .month{
        text-transform: capitalize;
    }

    .booktitle, .journal, .publisher, .institution{
        font-weight: lighter;
        font-style: italic;
    }
    
    .buttons{
        // display: none;
        margin-top: 0.5rem;

        button{
            padding: 0.1rem;
            color: #ff4e3c;
            background-color: transparent;
            text-transform: uppercase;
            font-weight: bold;
            border:none;
            cursor: pointer;

            &:hover{
                color: white;
                cursor: pointer;
            }
        }
        
    }

    .details{
        display: none;
        padding: .75em;
        box-sizing: border-box;
        font-size: 0.9em;
        color: white;
        background-color: #2e2e2e;
        border-radius: 0 0  0.5rem 0;

    }
    .abstract{
        font-family: monospace;
        text-align: justify;
    }
        
    .raw-bib{
        font-family: monospace;
        white-space: pre-wrap;  
        word-break: break-all;
    }
    
    .active{
        display: block;
    }
    
}

.bib-entry.active{
    display: flex;
}