@import "page";

.img-gallery{
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;

    h1{
        color: #e37968;
    }
    
    a{
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        
        background-color: #333333;
        border-radius: 0.2rem;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62); 
        box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62);

        color: #ff8476;
        text-decoration: none;
        font-size: 0.6rem;

        img{
            width: 100%;
            height: 225px;
            padding: 0;
            object-fit: cover;
            border-bottom: #da5540 solid;
            border-radius: 0.1rem 0.1rem 0 0;
        }
        span{
            display: flex;
            min-height: 40px;
            margin: 0.3rem 0.5rem;
            word-wrap: break-word;
            text-align: center;
            align-items: center;
            font-style: normal;
            font-weight: lighter;

        }
        &:hover{
            text-decoration: none;
        }
    }
}

.img-collection{
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;

    img{
        width: 350px;
        height: 250px;

        padding: 0;
        object-fit: cover;
        border-radius: 0.1rem 0.1rem 0.2rem 0.2rem;
        
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62); 
        box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62);
        cursor: pointer;
    }
}

.focus-view{
    
    display: none;
    z-index: 6;
    
    &.overlay{
        position: fixed;
        top:0;
        left: 0;
        width: 100vw;
        height: 100vh;

        align-items: center;
        justify-content: center;
    }
    .focus-bg{
        background-color: #000000be;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    

    .picture{
        box-sizing: border-box;
        object-fit: contain;
        
        max-height: 80vh;
        max-width: 90vw;
        padding: 0;
        border-radius: 0.1rem 0.1rem 0.2rem 0.2rem;
        
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62); 
        box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62);
        z-index: 6;
    }      
}

.active{
    display: flex;
}