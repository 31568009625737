.searchbar{
    position: relative;
    width: 9.063rem;
    height: 1.438rem;
    margin-right: 1.2rem;

    border-style: none;
    border-radius: 4rem;
    background-color: #ee342c;
    -webkit-box-shadow: inset 0px 0px 4px 0px #000000b9; 
    box-shadow: inset 0px 0px 4px 0px #000000b9;
    
    &.active{
        border-radius: 0.4rem 0.4rem 0 0;
        .results{
            display: block;
        }
    }

    &>input{
        width: 100%;
        padding-left: 0.6rem;
        padding-right: 1.6rem;
        box-sizing: border-box;
        background-color: transparent;
        border-radius: 3rem;
        border-style: none;
        outline: none;
        
        
        font-size: 0.6rem;
        font-weight: 600;
        color: white;

        &::placeholder{
            font-size: 0.6rem;
            font-weight: 600;
            color: white;
        }
    }

    &>.results{
        display: none;
        position: absolute;
        top:calc(100% + 4px);
        left: 0;
        right: 0;
        max-height: 15.286rem;
        padding: 0.2rem;
        overflow-y: scroll;
        z-index: 5;

        @include selector-box;
        border-radius: 0 0 0.3rem 0.3rem;
        
        li{
            width: 100%;
            margin: 0.2rem 0;
            padding: 0.2rem;
            box-sizing: border-box;


            border-radius: 0.4rem;
            background-color: #333333;
            cursor: pointer;

            &>a{
                display: flex;
                flex-direction: column;
                color: white;
                font-size: 0.55rem;
                text-decoration: none;
            }
            
            &:hover{
                background-color: #3c3c3c;
            }
            
            span:nth-child(2){
                margin-top: 0.2rem;
                font-weight: lighter;
            }
        }
    }

    .searchbar-icon {
        position: absolute;
        top:0.3rem;
        right: 0.6rem;
        color: white;
    }
}