.text-wrapper{
    // width: 40rem;
}

@mixin selector-box {
    border-style: solid;
    border-width: 0.025rem;
    border-radius: 0.3rem 0.3rem 0 0;
    background-color: #2e2e2e;
    border-color: #da5540;
}

.selector-title{
    @include selector-box;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.selectors{
    display: flex;
    justify-content: left;
    align-items: stretch;
}

.multi-select{
    position: relative;
    width: fit-content;
    padding: 0.75em 0.75rem;
    padding-right: 1.2rem;
    @include selector-box;

    &#select-left{
        border-radius: 0 0 0 0.3rem;
    }
    &#select-middle{
        border-radius: 0;
    }
    &#select-right{
        border-radius: 0 0 0.3rem 0;
    }
    
    &:hover{
        border-bottom-style: none;

        &#select-left, &#select-middle, &#select-right{
            border-radius: 0;
        }
        
        &>.select-options{
            display: block;
        }
    }
    
    .select-options{
        display: none;
        position: absolute;
        width: 100%;
        top: calc( 100% + 0rem );
        left: -0.025rem;
        border-style: solid;
        border-width: 0.025rem;
        border-color: #da5540;
        border-top-color: #732d21;
        // border-top-style: none;
        // border-radius: 0.3rem 0.3rem 0 0;
        background-color: #333333;
        z-index: 5;

        ul{
            margin: 0 !important;
            overflow-y: scroll;
            max-height: 45vh;

            li{
                position: relative;
                cursor: pointer;
                padding: 0.25rem;
                margin: 0.25rem 0.2rem;
                border-radius: 0.25rem;
                background-color: #3c3c3c;

                &:hover{
                    background-color: #545454;
                }

                &.active{
                    background-color: #da5540;
                    &::after{
                        display: block;
                        position: absolute;
                        top: 0.15rem;
                        right: 0.5rem;
                        font-weight: bolder;
                        color: white;
                        font-size: 0.75rem;
                        content: "+";
                        transform: rotate(45deg);
                    }
                }
            }

            &#dateList>li{
                text-align: center;
            }
        }
    }
}

.size-sm{
    display: none;
}
.size-lg{
    display: block;
}
@media only screen and (max-width: 425px) {
    .size-sm{
        display: block;
    }
    .size-lg{
        display: none;
    }
}
