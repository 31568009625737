.image_scroller{
    padding: 1rem 0;
    justify-content: center;
    flex-direction: row;
    display: flex;
    column-gap: 1rem;
    box-sizing: border-box;
    position: relative;
    padding: 0 75px;
    
    .img-view{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0.2rem;
        -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62); 
        box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.62);
    }

    button{
        position: absolute;
        top: calc( 50% - 35px);

        &:nth-of-type(1){
            left: 0;
        }
        &:nth-of-type(2){
            right: 0;
        }
    }
}