header{

    .header-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.4rem 1rem;
        box-sizing: border-box;
        
        font-size: 1rem;
        font-weight: 600;
        /* font-style: italic; */
        background-color: #333333;
        color: white;
        box-shadow: 0px 5px 12px -6px rgba(0,0,0,0.75);
        
        .partner-logos{
            a{
                text-decoration: none;
            }
        }

        img{
            height: 1.15rem;
        }
    }

    .header-bottom{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .mobile-nav{
        display: none;
        align-items: center;
        margin-left: 1rem;
        font-size: 1rem;
    }

    .mobile-nav .hamburger{
        position: relative;
        height: 0.7rem;
        width: 1.2rem;
        margin-left: 0.6rem;
    }

    .mobile-nav div:hover{
        color: #ca3e30;
    }

    .mobile-nav div{
        cursor: pointer;
    }

    .hamburger .bar{
        display: block;
        width: 1.2rem;
        height: 0.125rem;
        margin-bottom: 0.2rem;
        background-color: #ffffff;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .hamburger .bar:nth-child(3){
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
    }

    .hamburger .bar:nth-child(2){
        position: absolute;
        top: calc( 50% - 0.05rem );
        left: 0;
    }

    .hamburger .bar:nth-child(1){
        position: absolute;
        top: 0;
        left: 0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
        background-color: #ca3e30;
    }

    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
        background-color: #ca3e30;
    }
    
}

@media only screen and (max-width: 1000px) {
    header{

        .header-top .partner-logos, .header-top>.title{
            display: none;
        }

        .header-top{
            position: fixed;
            width: 100vw;
            z-index: 5;
        }
        
        .header-top img{
            height: 1.25rem;
        }

        .mobile-nav{
            display: flex;
        }

        .header-bottom{
            position: fixed;
            top: 2.3rem;
            bottom: 0;
            left: -100vw;
            overflow-y: auto;
            padding-bottom: 1rem;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            
            border-radius: 0px 0px 5px 5px;
            background-color: white;
            z-index: 5;

            transition: all 250ms ease-in-out;
        }

        .header-bottom.active{
            left: 0;
        }

        .header-bottom ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
            margin: 0 0 0 0.5rem ;
        }

        .header-bottom li{
            all: unset;
        }

        .searchbar{
            display: none;
        }
    }
}