.alternating-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    & .page-overview{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 3rem;

        .text{
            max-width: 18rem;
        }

        &:nth-last-of-type(odd)>.img-left, &:nth-last-of-type(even)>.img-right{
            display: none;
        }

        a{
            width: 35%;
            height: 22.5%;


            .image {
                width: 100%;
                height:100%;
                object-fit: contain;
                border-radius: 0.5rem;
                box-sizing: border-box;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .alternating-list .page-overview a{
        width: 100%;
        max-width: 18rem;

        &.img-right{
            display: none !important;
        }

        &.img-left{
            display: block !important;
        }
    }
}