@import "colors";

.nav-dropdown-menu{
    display: flex;
    margin-left: 1.2em;
    margin-top: 0.9rem;
    column-gap: 0.8rem;
    z-index: 3;

    font-weight: 600;
    font-size: 0.85rem;

    li{
        cursor: pointer;
    }

    a{
        padding: 0.2rem;
        color: white;
        text-underline-offset: 0.2rem;
        text-decoration: none;
    }

    &>li:not(.has-dropdown):hover a{
        color: white;
    }

    li:hover>a, .nav-dropdown-submenu> li:hover>a{
        text-decoration: underline;
    }
    
    .has-dropdown {
        position: relative;

        .nav-dropdown-submenu{
            position: absolute;
            top: calc(100% + 0.2rem);
            left: 0;
            min-width: 7rem;

            border-radius: 0 0.2rem 0 0.2rem;
            background: $core-red;
            white-space: nowrap;
            font-size: 0.6rem;
        
            opacity: 0;
            transform: scaleY(0);
            transform-origin: top center;
            transition: all 250ms ease-in-out;

            :first-child{
                margin-top: 1rem;
            }
        
            :last-child{
                margin-bottom: 1rem;
            }

            li{
                margin: 0.2rem;
            }

            li:hover>a{
                color: $core-grey;
            }
        
            a{
                margin: 1rem;
            }
            

            .nav-dropdown-submenu{
                left: calc( 100% + 0.2rem );
                top:0;
            }
        }
        
        &:hover>a{
            border-radius: 0.2rem 0.2rem 0 0;
            background-color: $core-red;
        }
    }
}

.nav-dropdown-menu > li:hover > a + .nav-dropdown-submenu,
.nav-dropdown-submenu > li:hover > a +.nav-dropdown-submenu{
    opacity: 1;
    transform: scaleY(1);
}

@media only screen and (max-width: 1000px) {

    .nav-dropdown-menu{
        display: unset;
        z-index: 3;
    
        font-weight: 600;
        font-size: 0.85rem;

        &:first-of-type{
            margin-top: 1rem;
        }
    
        li{
            cursor: pointer;
        }
    
        a{
            color: #ca3e30;
            text-underline-offset: 0.2rem;
            text-decoration: none;
        }
    
        &>li:not(.has-dropdown):hover a{
            color: #962e23;
        }
    
        li:hover>a, .nav-dropdown-submenu> li:hover>a{
            text-decoration: underline;
        }
        
        .has-dropdown {
            position: unset;
    
            .nav-dropdown-submenu{
                position: unset;
    
                border-radius: 0 0.2rem 0 0.2rem;
                background: none;
                white-space: nowrap;
                
                opacity: 1;
                transform: scaleY(1);
                transform-origin: top center;

                li:hover>a{
                    color: $core-grey;
                }
            }
            
            &:hover>a{
                border-radius: 0.2rem 0.2rem 0 0;
                background: none;
            }
        }
    }
}

