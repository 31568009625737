$core-red: #ca3e30;
$core-grey: #333333;
$core-light: white;

$core-light-grey: #545454;


$site-title-color: #545454;



$markdown-text-color: white;
$markdown-link-color: #cccccc;
$markdown-header-color: #da5540;