.wrapper{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.text-wrapper{
    margin-top: 3rem;
    margin-bottom: 5rem;
    max-width: 40rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    color: white;
    font-size: 0.6rem;
    position: relative;
}

.text-wrapper h1, .text-wrapper h2, .text-wrapper h3, .text-wrapper h4{
    color: #e37968;
}

.page-title{
    max-width: 70%;
}

.text-wrapper h4{
    color: aliceblue;
}

.text-wrapper img{
    max-width: 100%;
    padding: 0.25rem;
    border-style: none;
}

.text-wrapper a{
    color: #ff8476;
    font-style: italic;
    text-decoration: none;
}

.text-wrapper p{
    padding: calc(1rem * 0.33) 0px;
}

.text-wrapper a:hover{
    text-decoration-line: underline;
}

.text-wrapper ul{
    margin-left: 2em;
} 

.text-wrapper ul li{
    list-style-type: square;
}

.text-wrapper code{
    color: #abb2bf;
    font-family: monospace;
    word-wrap: normal;
    line-height: 1.45;
} 

.text-wrapper pre{
    width: fit-content;
    max-width: calc(100vw - 0.2rem);
    overflow-y: auto;
    padding: 0.2rem;
    box-sizing: border-box;
    background-color: #282c34;;
    border-radius: 6px;
    -webkit-box-shadow: inset 0px 0px 4px 0px #00000023; 
    box-shadow: inset 0px 0px 4px 0px #00000023;
}

table {
    display: inline-block;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    padding: 0.2rem 0.75rem 0.2rem 0.5rem;
    font-size: 0.54rem;
}

thead{
    :first-child{
        border-top-left-radius: 0.2rem;
    }
    :last-child{
        border-top-right-radius: 0.2rem;
    }
}

tr:nth-child(even){
    background-color: #00000024;
}

tr:hover {
    background-color: #da554073;
}

th {
    font-size: 0.6rem;
    text-align: left;
    background-color: #da5540;
    color: white;
}

.horizontal-scroll{
    max-width: calc(100vw - 0.5rem);
    overflow-x: auto;
}

@media screen and (min-width: 39.5rem) {
    .text-wrapper{
        width: 40rem;
    }
}

@media only screen and (max-width: 1000px) {

    .text-wrapper{
        margin-top: 1rem;
    }

    .page-title{
        max-width: unset;
    }
}