@import url('http://fonts.cdnfonts.com/css/inter');

@import "nav-menu";
@import "header";
@import "footer";
@import "markdown-style";
@import "bib";
@import "publications";
@import "searchbar";
@import "site-overview";
@import "page";
@import "gallery";
@import "news";
@import "image_scroller";
@import "icons";


*{
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    list-style-type: none;
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}

html{
    font-size: 28px;
}

body{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-image: url("/assets/img/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
}

.page-title{
    font-size: 1.5rem;
    font-weight: 800;
    color: #ff4e3c;
    margin-bottom: 1rem !important;
}

::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}