.news{
    display: flex;
    flex-direction: column;
    padding: 2rem;

    .news-title{
        font-size: 1.5rem;
        margin-bottom: 0.7rem;
        color: #e37968;
        font-weight: 900;
    }
    
    .news-wrapper{
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: left;
    
        &>.news-post{
            display: flex;
            flex-direction: column;
            width: 325px;
           
            background-image: linear-gradient(rgba(0, 0, 0, 0.252), rgba(0, 0, 0, 0.7)); 
            border: #da5540 solid 1px;
            border-bottom: #da5540 solid 3px;
            border-radius: 0.1rem 0.1rem 0.4rem 0.4rem;
            box-shadow: 5px 5px 15px -4px #00000047;
            -webkit-box-shadow: 5px 5px 15px -4px #000000;
    
            &>img{
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-radius: 0.1rem 0.1rem 0 0;
            }
    
            &>.news-info{
                display: flex;
                flex-direction: column;
                padding: 1rem;
                span{
                    color: gray;
                    margin-top: 0.5rem;
                }
        
                a{
                    margin-top: 1rem;
                    font-size: 1rem;
                    color: white;
                    font-weight: 700;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
    
            &:first-of-type{
                width: 600px;
                &>img{
                    height: 400px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .news{
        margin-top: 3rem;
    }
}
@media only screen and (max-width: 500px) {
    .news{
        padding: 1rem;

        .news-wrapper{
            justify-content: center;

            &>.news-post:first-of-type, &>.news-post{
                width: 100%;

                &>img{
                    height: 200px;
                }
            }
        }
    }
}
