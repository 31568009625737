
@mixin icon-base{
    cursor: pointer; 
    width: 70px;
    height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent
}

@mixin arrow{
    @include icon-base;
    background-image: url(/assets/icons/arrow.svg);
}

.arrow-right{
    @include arrow;
}

.arrow-left{
    @include arrow;
    transform:scaleX(-1);
}